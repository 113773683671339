
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import { Auth } from 'aws-amplify'
import { LOGGED_USERS_INDEX_URL, LOGIN_URL } from 'links'
import { log } from 'logger'
import { NextPage } from 'next'
import useTranslation from 'next-translate/useTranslation'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

 const _getStaticProps = async () => ({ props: {} })

const Home: NextPage = () => {
  const router = useRouter()
  const { lang } = useTranslation()
  useEffect(() => {
    const verifyAuthentication = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser()
        if (user) {
          router.replace(LOGGED_USERS_INDEX_URL, undefined, { locale: lang })
        } else {
          router.replace(LOGIN_URL, undefined, { locale: lang })
        }
      } catch (error) {
        router.replace(LOGIN_URL, undefined, { locale: lang })
        log.debug(error)
      }
    }
    verifyAuthentication()
  }, [router, lang])
  return (
    <>
      <Head>
        <title>Tensor Energy</title>
      </Head>
      <main />
    </>
  )
}

export default Home


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        let res = _getStaticProps(ctx)
// @ts-ignore
        if(typeof res.then === 'function') res = await res
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          ...res,
// @ts-ignore
          props: {
// @ts-ignore
            ...(res.props || {}),
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/index',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  